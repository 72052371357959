import React, {useEffect, useRef, useState} from 'react';
import {ImageBackground, StyleSheet} from 'react-native';
import {ActivityIndicator, Button, IconButton, Text, TextInput, useTheme} from "react-native-paper";
import {StepOf} from '../../components/globals/step-of';
import {colors, RootParamList} from '../../utils/settings';
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {FBox} from '../../components/globals/fbox';
import {useGs1codeMutation} from '../../api/okusuri';
import {toastMessage} from '../../utils/toast';
import {Scanner} from "../../components/globals/scanner";
import {TextResult} from "dynamsoft-javascript-barcode";


export default function AddMedicine({route, navigation}) {
    let cameraRef = useRef<any>()
    const [medData, setMedData] = useState<any>({})
    const [medImage, setMedImage] = useState<any>(null);
    const [scannedGS1Code, setScannedGS1Code] = useState<string>('');
    const [inputGS1Code, setInputGS1Code] = useState<string>('');
    const [gs1Code, {isLoading, isError}] = useGs1codeMutation()
    const [med, setMed] = useState<any>(null)
    const [medicine, setMedicine] = useState<any>(null);
    const nav = useNavigation<NativeStackNavigationProp<RootParamList>>();
    const timeout = React.useRef(undefined);
    const isRunning = React.useRef(false)
    const theme = useTheme();
    const {allMeds = {}} = route.params ?? {}


    useEffect(() => {
        if (route)
            setMedicine(null)
    }, [route])

    useEffect(() => {
        let curStep = 1;
        if (medicine)
            curStep = 2;
        else
            curStep = 1;
        navigation.setOptions({
            headerTitleAlign: 'center',
            headerLeft: () => (
                <IconButton icon={"close"} iconColor={colors.white} onPress={handleBackNav}/>
            ),
            headerRight: () => {
                return (
                    curStep === 2 ?
                        <Text style={[styles.text, {color: colors.primary}]}
                              onPress={() => nav.navigate("editMedicine", {
                                  medData: medData,
                                  allMeds: allMeds
                              })}>次へ</Text>
                        :
                        <StepOf total={2} current={curStep}/>
                )
            }
        });
    }, [medicine]);

    useEffect(() => {
        if (isError && scannedGS1Code !== '') {
            toastMessage({msg: `Error getting medicine data from ` + scannedGS1Code}).catch(err => console.log(err))
            setScannedGS1Code("")
        }
    }, [isError])

    useEffect(() => {
        if (med?.id) {
            setMedData(med)
            setMedicine(medImage);
        }
    }, [med])

    function stop() {
        isRunning.current = false;
        clearTimeout(timeout.current);
    }

    const handleBackNav = () => {
        if (navigation.canGoBack())
            navigation.goBack()
        else
            navigation.replace('dashboard')
    }
    const getMedData = (code) => {
        gs1Code({gs1code: code}).then(res => {
            // @ts-ignore
            if (res.data) {
                // @ts-ignore
                setMed(res.data)
            }
        })
    }
    const onSubmitManual = () => {
        if (inputGS1Code !== "") {
            const code = inputGS1Code.replace(/\(\d+\)/, "")
            takePic(code).catch(err => console.log(err));
        }
    }
    const takePic = async (gsCode) => {
        let options = {
            quality: 1,
            base64: true,
            exif: false
        };
        if (gsCode && gsCode !== "") {
            try {
                setMedImage(await cameraRef.current.takePictureAsync(options));
            } catch (err) {
                console.log(err)
            }
            setScannedGS1Code(gsCode)
            getMedData(gsCode)
        }
    }

    const retake = () => {
        setMedicine(null);
        setScannedGS1Code('');
        setInputGS1Code('');
    }

    return (
        <FBox style={styles.container}>
            {medicine ?
                <>
                    <ImageBackground source={{uri: medicine.uri}} style={styles.camera}
                                     key={`medPhoto`}>
                        <Button icon={"camera"} labelStyle={[styles.text, {fontSize: 16}]} mode={"outlined"}
                                style={styles.photoPreview}
                                onPress={retake}>撮影をしなおす。</Button>
                    </ImageBackground>
                    <FBox style={styles.photoType}>
                        <Text style={styles.cameraText}>{scannedGS1Code}</Text>
                        <Text style={{textAlign: 'center'}}>{medData['CYOUZAI_HOUSOU_UNIT_NAME']}</Text>
                    </FBox>
                    <FBox style={styles.camera}>

                    </FBox>
                </>
                :
                <>
                    {isLoading && scannedGS1Code !== '' ?
                        <FBox style={{...styles.camera}}>
                            <FBox>
                                <ActivityIndicator size="large" color={theme.colors.primary}/>
                                <Text style={{fontStyle: 'italic', textAlign: 'center'}}> Verifying Barcode...</Text>
                            </FBox>
                        </FBox>
                        :
                        <Scanner ref={cameraRef} onResult={(res: TextResult) => {
                            console.log(res)
                            if (!isLoading) {
                                takePic(res.barcodeText).catch(err => {
                                    console.log(err)
                                })
                            }

                        }}/>
                    }
                    <FBox style={{flex: 2}}>
                        <Text style={styles.text}>服用中のお薬のバーコードをスキャンしてください。</Text>
                        <FBox style={styles.buttonContainer}>
                            <TextInput
                                style={{marginRight: 10}} mode={"outlined"} value={inputGS1Code}
                                onChangeText={(value) => setInputGS1Code(value)}
                                placeholder={"Manually Enter the gs1Code"}/>
                            <IconButton onPress={onSubmitManual} mode={"outlined"}
                                        icon={"send"}/>
                        </FBox>
                    </FBox>
                </>
            }
        </FBox>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    camera: {
        flex: 1,
        flexDirection: 'column-reverse',
        paddingBottom: 10
    },
    photoType: {
        display: 'flex',
        alignItems: 'center'
    },
    cameraText: {
        justifyContent: 'center',
        fontSize: 20,
        color: 'white',
        textAlign: 'center',
        margin: 10,
        padding: 2,
        display: 'flex',
        backgroundColor: colors.textDark,
        width: 'max-content'
    },
    photoPreview: {
        alignSelf: 'flex-end',
        fontSize: 16,
        backgroundColor: colors.textDarker,
        borderRadius: 20,
        border: 0,
        padding: 0
    },
    button: {
        backgroundColor: colors.textDark,
        borderRadius: 10,
        width: 'max-content',
        margin: 'auto'
    },
    nextButton: {
        marginRight: 10
    },
    buttonContainer: {
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
        flex: 1
    },
    circle: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: 70,
        height: 70,
        borderRadius: 35,
        backgroundColor: colors.white,
        border: '5px double',
    },
    text: {
        justifyContent: 'center',
        fontSize: 20,
        color: 'white',
        textAlign: 'center',
        margin: 10,
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
});