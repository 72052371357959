
import { useEffect, useRef, useState } from 'react';
import { ImageBackground, StyleSheet, TouchableOpacity } from 'react-native';
import { ActivityIndicator, Button, IconButton, Text, useTheme } from "react-native-paper";
import { colors } from '../../../utils/settings';
import { StepOf } from '../../../components/globals/step-of';
import { FBox } from '../../../components/globals/fbox';
import { DoseList } from '../../../components/medicine/dose-list';
import { useEditMedMutation } from '../../../api/okusuri';
import { toastMessage } from '../../../utils/toast';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../utils/store/global';
import { useRewardUserMutation } from '../../../api/account';
import {Camera, CameraType} from "expo-camera";

export default function RecordMedicine3({ route, navigation }) {
    let cameraRef = useRef<any>()
    const [medData, setMedData] = useState<any>(null)
    const [allMedData, setAllMedData] = useState<any>(null)
    const [curMed, setCurMed] = useState<number>(0)
    const [permission, requestPermission] = Camera.useCameraPermissions();
    const [editMed, { isLoading }] = useEditMedMutation()
    const [rewardUser, { isLoading: isRewarding }] = useRewardUserMutation();
    const [medicine, setMedicine] = useState<any>(null);
    const user = useSelector((state: GlobalState) => state.user)

    const theme = useTheme();

    useEffect(() => {
        let curStep = 1;
        curStep = medicine ? 2 : 1;
        navigation.setOptions({
            headerTitleAlign: 'center',
            headerLeft: () => (
                <IconButton icon={"close"} iconColor={colors.white} onPress={handleBackNav} />
            ),
            headerRight: () => {
                return (
                    curStep === 2 ?
                        isLoading || isRewarding ?
                            <ActivityIndicator size={'small'} color={colors.white} />
                            :
                            <Text style={[styles.text, { color: colors.primary }]}
                                onPress={() => updateMed()}>次へ</Text>
                        :
                        <StepOf total={2} current={curStep} />
                )
            }
        });
    }, [medicine]);
    useEffect(() => {
        if (!permission?.granted) {
            requestPermission().catch(err => alert(err))
        }
        if (route.params?.medData) {
            setMedData(route.params.medData)
        }
        if (route.params?.allMedData) {
            setAllMedData(route.params.allMedData)
        }
    }, [])

    const handleBackNav = () => {
        if (navigation.canGoBack())
            navigation.goBack()
        else
            navigation.replace('dashboard')
    }


    const rewardUserMed = async (tempMedData) => {
        if (user.address) {
            rewardUser({ address: user.address, userId: user.id, medicineId: tempMedData.medicineId }).unwrap().then(async (res) => {
                if (res.error) {
                    toastMessage({ msg: res.message });
                    return;
                }
                await toastMessage({ msg: `薬が正常に記録されました` })
                navigation.navigate('dashboard')
            })
        } else {
            await toastMessage({ msg: `薬が正常に記録されました` })
            navigation.navigate('dashboard')
        }
    }
    const updateMed = async () => {
        const tempM = allMedData ? allMedData[curMed] : medData
        const tempMedData = {
            userId: tempM.user_id,
            id: tempM.id,
            medicineName: tempM.medicine_name,
            medicineId: tempM.id,
            takeMedicineIconType: tempM.medicine_icon_type,
            takeMedicineTimeType: tempM.take_medicine_time_type,
            dose: tempM.dose
        }
        editMed({ ...tempMedData }).unwrap().then(async (res) => {
            if (res.error) {
                toastMessage({ msg: res.message });
                return;
            }
            if (allMedData) {
                if (curMed + 1 > allMedData.length - 1) {
                    await rewardUserMed(tempMedData)
                    return;
                } else {
                    setCurMed(curMed + 1);
                    setMedicine(null)
                }
            }
            if (medData) {
                await rewardUserMed(tempMedData)
            }
        }).catch(err => {
            toastMessage({ msg: err.message ?? "Server Error Response" })
        })
    }

    const takePic = async () => {
        let options = {
            quality: 1,
            base64: true,
            exif: false
        };
        let newMedicine = await cameraRef.current.takePictureAsync(options);
        setMedicine(newMedicine);
    };

    if (!permission?.granted) {
        return (
            <FBox style={styles.container}>
                <FBox>
                    <Text style={{ ...styles.text, color: colors.white }}>Permission to use Camera</Text>
                    <Button onPress={() => requestPermission().catch(err => alert(err)).then(res => {
                        if (res?.status === "denied") {
                            alert("Sorry We can not show camera as the permission is denied by the browser ")
                        }
                    })} style={styles.button}>Grant
                        permission</Button>
                </FBox>
            </FBox>
        );
    }

    return (
        <FBox style={styles.container}>
            {medicine ?
                <FBox style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <ImageBackground source={{ uri: medicine.uri }} style={{ ...styles.camera, borderWidth: 1, borderColor: theme.colors.onSurface, borderRadius: 20, paddingBottom: 0 }}
                        key={`photo1`} imageStyle={{ borderRadius: 20 }}>
                        <Button icon={"camera"} labelStyle={[styles.text, { fontSize: 16 }]} mode={"outlined"}
                            style={styles.photoPreview}
                            onPress={() => (!isLoading && !isRewarding) ? setMedicine(null) : {}}>服用する前の写真を撮ります (撮り直し)
                        </Button>
                    </ImageBackground>
                    <FBox style={{ flex: 1 }}>
                        {(!isLoading && !isRewarding) && <Button style={{ backgroundColor: theme.colors.primary, marginVertical: 30, marginHorizontal: 60 }}
                            onPress={updateMed}
                            labelStyle={{ color: theme.colors.onPrimary }}>次へ</Button>}
                        <Text style={styles.text}>服用するお薬を撮影してください。
                            ※お薬飲み忘れで時間が異なる場合も同様に撮影ください。</Text>
                    </FBox>
                    {(isLoading || isRewarding) && <FBox>
                        <ActivityIndicator size={'large'} color={colors.primary} />
                        <Text style={{ fontStyle: 'italic', textAlign: 'center' }}> Recording Medicine...</Text>
                    </FBox>}
                </FBox>
                :
                <>
                    <Camera style={styles.camera} type={CameraType.back} ref={cameraRef} />
                    <FBox style={styles.photoType}>
                        <Text style={styles.cameraText}>服用する前の写真を撮ります</Text>
                    </FBox>
                    <FBox>
                        {(allMedData && allMedData[curMed]) &&
                            <DoseList list={[allMedData[curMed]]} swipeable={false} recordMed={true} />
                        }
                        {medData &&
                            <DoseList list={[medData]} swipeable={false} recordMed={true} />
                        }
                    </FBox>
                    <FBox style={{ flex: 2 }}>
                        <Text style={styles.text}>服用するお薬を撮影してください。</Text>
                        <FBox style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.circle} onPress={takePic}>
                                <IconButton icon={"camera"} iconColor={colors.black} size={35} />
                            </TouchableOpacity>
                        </FBox>
                    </FBox>
                </>
            }
        </FBox >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    camera: {
        flex: 1,
        flexDirection: 'column-reverse',
        paddingBottom: 10
    },
    photoType: {
        display: 'flex',
        alignItems: 'center'
    },
    cameraText: {
        justifyContent: 'center',
        fontSize: 20,
        color: 'white',
        textAlign: 'center',
        margin: 10,
        padding: 2,
        display: 'flex',
        backgroundColor: colors.textDark,
        width: 'max-content'
    },
    photoPreview: {
        alignSelf: 'flex-end',
        fontSize: 16,
        backgroundColor: colors.textDarker,
        borderRadius: 20,
        border: 0,
        padding: 0
    },
    button: {
        backgroundColor: colors.textDark,
        borderRadius: 10,
        width: 'max-content',
        margin: 'auto'
    },
    nextButton: {
        marginRight: 10
    },
    buttonContainer: {
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        flex: 1
    },
    circle: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: 70,
        height: 70,
        borderRadius: 35,
        backgroundColor: colors.white,
        border: '5px double',
    },
    text: {
        justifyContent: 'center',
        fontSize: 20,
        color: 'white',
        textAlign: 'center',
        margin: 10,
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
});