import {Divider, List, Text} from "react-native-paper";
import {FBox} from "../../components/globals/fbox";
import React from "react";
import {FlatList} from "react-native";

export const TermsAndConditions = () => {
    const data = [
        {
            description: "この利用規約（以下「本利用規約」といいます。）は、株式会社HealthCareGate（以下「当社」といいます。）が提供する服薬管理サービス「DrugN」α版（以下「本サービス」といいます。）の利用に関する条件を定めるものです。本利用規約は、本サービスを利用する全ての方（以下「利用者」といいます。）に適用されます。本サービスをご利用になる前に、本利用規約の内容をよくお読みください。本利用規約に同意されない場合は、本サービスを利用することはできません。"
        },
        {
            title: "第1条（目的",
            list: [
                "本利用規約は、本サービスの利用（パソコン、スマートフォンその他の利用端末の種類を問わず、また、ウェブサイト及びアプリケーションを通じた利用を含みます。）に関する条件及び当社と利用者との間の権利義務関係を定めることを目的としています。",
                "利用者は、本サービスを利用するにあたり、本利用規約を遵守するものとします。",
                "利用者は、本利用契約の期間中、本サービスの通常想定される利用目的の範囲内で、本利用規約に従って本サービスを利用することができます。"
            ]
        },
        {
            title: "第2条（定義）",
            description: "本利用規約において使用する以下の用語は、以下に定める意味を有するものとします。",
            list: [
                "「利用者」とは、第3条に基づき当社との間で本利用契約を締結した個人及び当社が本サービスの利用を許諾した者をいいます。",
                "「本利用契約」とは、当社と利用者との間で締結される本サービスの利用に関する契約をいいます。",
                "「当社サイト」とは、当社が運営するウェブサイト「https://healthcaregate.co.jp/」をいいます。",
                "「コンテンツ」とは、利用者が本サービス又は当社サイト上でアクセスすることができる一切の情報（文章、画像、動画、音声その他のデータを含みますが、これらに限りません。）をいいます。",
                "「ユーザーコンテンツ」とは、利用者が本サービスにアップロード又は送信するコンテンツをいいます。",
                "「知的財産権」とは、特許権、実用新案権、商標権、意匠権、著作権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含む。）をいいます。",
                "「反社会的勢力等」とは、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます。",
                "「法令等」とは、法律、政令、省令、通達、規則、条例、裁判所の判決、決定、命令又は強制力のある行政処分、ガイドラインその他の規制の総称をいいます。",
                "「利用期間」とは、令和5年2月1日から令和6年3月31日[LO1] をいいます。",
                "「個人情報」とは、個人に関する情報であって、個人情報の保護に関する法律第2条第1項により定義されたものをいいます。"
            ]
        },
        {
            title: "第3条\t（本サービスの申込）",
            list: [
                "本サービスの利用を希望する方（以下「登録希望者」といいます。）は、本利用規約を遵守することに同意し、かつ当社の定める情報（以下「登録情報」といいます。）[LO2] を当社指定の方法で当社に提供することにより、登録を申請することができます。\n" +
                "登録情報とは、登録希望者のメールアドレス及びウォレットアドレスを指し、登録希望者は、登録希望者を特定し得る氏名、住所、生年月日等の個人情報を含まないメールアドレスを用いるものとします。[LO3] （当社側で類推可能なメールアドレスの場合、登録希望者自身において自らを特定しないものとして入力したものとします。）\n",
                "登録希望者は登録の申請を行う時点で、本利用規約に同意したものとみなします。",
                "当社は、当社の基準に従って登録の可否を判断し、その結果を登録希望者に通知します。当社が登録を認める旨の通知を行った時点で登録が完了し、本利用契約は成立します。",
                "登録希望者が以下の各号のいずれかに該当し又は該当するおそれがあると当社が判断した場合には、登録を拒否することができます。なお、登録申請が承認されなかったとしても、当社はその理由を開示する義務を負いません。\n" +
                "\t(1)  当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記入漏れがある場合\n" +
                "\t(2)  登録情報であるメールアドレスから登録希望者の個人情報を特定し得る場合[LO4] \n" +
                "\t(3)  反社会的勢力等に所属し若しくは過去に所属していた場合、その他反社会的勢力等と何らかの交流若しくは関与等がある場合\n" +
                "\t(4)  過去に、本サービスその他当社の他のサービスの利用登録の拒否若しくは取消等の措置を受けた場合、第11条に基づく利用停止の措置を受け若しくは本利用契約を解約された場合、又は当社との契約その他の合意に違反した場合\n" +
                "\t(5)  その他当社が登録を不適当と認める場合",
                " 本利用契約の有効期間中に利用者が前項の各号のいずれかに該当した場合、当該利用者はただちに当社に通知しなければならないものとします。",
                "利用者は、登録情報に誤り等があった場合又は変更が生じた場合、当社所定の方法により、当該内容を当社に通知し又は本サービス上で自ら修正、変更しなければなりません。",
            ],
        },
        {
            title: "第4条\t（アカウント）",
            list: [
                "利用者は、ID[LO5] 、パスワード[LO6] 、秘密鍵、その他自己のアカウントに関する情報を、自己の責任において安全に管理・保管し、第三者による不正使用を防止するために必要な措置を講じるものとします。",
                "利用者は、自己のアカウントを第三者に貸与、共有、譲渡、名義変更その他の方法により第三者に使用させてはなりません。",
                "当社は、IDとパスワードの一致を確認した場合、当該ID及びパスワードの保有者として登録された利用者が本サービスを利用したものとみなします。",
                "利用者は、本サービスのアカウントの不正利用又は第三者による使用又はそれらのおそれが判明した場合には、ただちにその旨を当社に通知するとともに、当社からの指示に従うものとします。",
                "アカウントの復元につきまして、当社は秘密鍵情報を所有していない為、対応不可能であることをここに記載するとともに利用者はこれに同意するものとします。"
            ]
        },
        {
            title: "第5条\t（本サービスの内容）[LO7] ",
            list: [
                "本サービスは、当社と提携関係にある薬局（以下、「本件薬局」といいます。）が利用者に対して処方する医薬品（以下、「本件医薬品」といいます。）につき、利用者の服薬行動の促進と本件薬局による利用者に対する服薬管理指導の補助を目的とするサービスです。ただし[LO8] 、α版サービスでは、本件薬局に限らず、利用者が医師及び薬剤師から正規に処方された医薬品をもって本件医薬品として取り扱うものとします。",
                "利用者は、医師及び薬剤師の処方に基づき本件医薬品を服薬し、当社所定の方法により服薬後の本件医薬品の医薬品包装の写真を撮影し、当該写真のデータ（以下、「本件データ」といいます。）を本サービス上に送信することで、当社が発行する独自トークン「DMT・DST・DPT・OkusuriNFT」[LO9] （以下「トークン」といいます。）を取得することができます。",
                "利用者は、本サービス上で、本件医薬品の服薬体験について、当社から任意で回答を求められることに同意するものとします。なお、利用者が、本件医薬品の服薬体験について回答した場合、当該回答データについては、個人が特定できない状態で当社が取得し、当社のデータベース上で保管されるものとします。[LO10]",
                "得られたデータにつきましては、使用技術の特徴上公開される可能性がある他、本サービス推進において必要と思われる第三者へ提供いたします。"
            ]
        },
        {
            title: "第6条\t（トークンについて）",
            list: [
                "利用者は、本サービス上で、本件サービス利用者に付与されるNFT（以下「本NFT」といいます。利用期間が終了した場合には、本件提供元のアドレスに返還されるものとします。）をセットし、前条第2項で定める行為をすることにより、トークンを取得することができます。ただし、α版サービス終了後には、本NFTを回収いたしますが、新たなNFTを当社から利用者に新たに付与するものとし、新たなNFTの返還は求めないものとします。",
                "利用者は、当社から付与されたトークンを利用者自身で管理するWalletアドレスにて管理することに同意するものとします。[LO11] ",
                "当社は、本条第1項により、利用者が得られるトークンの獲得量及び獲得レートを、当社が別途定める基準に則り、随時変更する場合がございます。",
                "当社は、下記の各号に定める事情があり、当社のユーザーに対するトークンの付与を取り消す必要があると合理的に判断される場合には、本条第1項のトークンの付与を取り消すことがございます。\n" +
                "\t(1)  利用者へのトークンの付与が誤っていた場合\n" +
                "\t(2)  利用者が、第7条で定める禁止事項を行っていることが判明した場合",
                "利用者は、保有するトークンを当社所定の手続に従い、アマゾンジャパン合同会社の発行するアマゾンギフトカードと交換することができます。[LO12] "
            ]
        },
        {
            title: "第7条\t（禁止事項[LO13] ）",
            description: "利用者は、本サービスの利用に関して、自己又は第三者をして、以下の各号の行為を行わないものとします。",
            list: [
                "本利用規約に違反する行為",
                "本件医薬品を服薬することなく、医薬品包装から、本件医薬品のみを出した本件データを送信する行為[LO14] ",
                "本件医薬品とは無関係な画像データ等を本サービスに送信する行為",
                "反社会的勢力等に対する利益供与その他反社会的勢力等に関与する行為",
                "虚偽の登録情報その他の本サービスの運営を妨害する行為",
                "アカウントの譲渡若しくは貸与、又は第三者に成りすます行為",
                "複数のアカウントを登録する行為",
                "本サービス又は当社サイトに関連するプログラムを変更、削除、逆コンパイル、逆アセンブル又はリバースエンジニアリングする行為",
                "法令等又は公序良俗に違反する行為",
                "その他当社が不適当と認める場合"
            ]
        },
        {
            title: "第8条\t（非保証）",
            list:[
                "本サービスは、医師及び薬剤師の処方に基づく利用者の服薬行動を促進することを目的とするものであり、利用者の治療若しくは、予防に使用されるものではなく、また、利用者の身体の構造若しくは機能に影響を及ぼすことを目的とするものではありません。[LO15] また、医薬品の服薬により、利用者に生ずる身体への影響について、当社は何ら保証するものではありません。",
                "当社が、利用者に対して提供するトークン、NFTを含む全ての無体物は、資金決済法第2条5項に定める「暗号資産」ではありません。[LO16] ",
                "本サービスを利用するために必要な機器、通信回線その他の利用環境は、利用者が自らの責任と費用負担で用意するものとします。",
                " 当社は、本サービスが、利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性・完全性・安全性を有すること、及び不具合が生じないことについて、何ら保証するものではありません。ただし、本サービスにセキュリティ上の欠陥、エラー、バグその他の不具合が存在する場合、当社は実務上可能な限りこれを修正するよう努めるものとします。",
                "当社は、明示又は黙示を問わず、本サービスを通じて提供されるコンテンツに関して何ら保証するものではありません。",
                "当社は、本件データを含む利用者が本サービスにアップロードするユーザーコンテンツが、本サービスの利用に起因して消失、消滅、変化等しないことについて保証を行うものではありません。利用者は、自己の責任において、これらを管理し、適宜バックアップ等を行ってから本サービスを利用するものとします。",
                "当社は、本サービスと連携する外部サービスの提供者である第三者が提供するサービス、情報、個人情報の管理等について一切の責任を負いません。利用者は、自己の責任において、外部サービスを利用するものとします。",
                "利用者は、本サービスの利用に関連して第三者との間で生じた取引、連絡、紛争等については、自己の費用負担と責任において対応及び解決するものとします。",
            ]
        },
        {
            title:"第9条\t（知的財産権等）",
            list:[
                "本サービスに関する知的財産権は全て当社又は当社に使用を許諾する正当な権利者（以下本条において「当社等」といいます。）に帰属しており、本利用契約の成立又は本サービスの利用の許諾は、利用者に対して本サービスの利用を超える当社等の知的財産権の利用許諾を意味するものではありません。",
                "本利用規約に明示的に規定されている場合を除き、本サービス及び本利用規約に関連して当社が利用者に提供した文章、画像、映像、音声、プログラム等一切のコンテンツに関する知的財産権その他一切の権利及び権限は、当社等に帰属します。",
                "利用者は、ユーザーコンテンツについて、自らがデータ送信等を行う適法な権利を有していること、及び当該データが第三者の権利を侵害していないことについて、当社に表明し、保証するものとします。",
                "ユーザーコンテンツの知的財産権は利用者に帰属します。利用者は当社に対し、本サービス上にコンテンツをアップロードした時点で、本サービス提供上必要な範囲における複製、翻案、公衆送信、派生著作物の作成、表示及び実行に関するライセンスを無償にて付与するものとします。なお、当該コンテンツについて利用者は著作者人格権を行使しないものとします。"
            ]
        },
        {
            title: "第10条   （データの利用）",
            description: "当社は、登録情報その他の利用者に関するデータを、本サービスを含む当社が提供し又は提供しようとしているサービスの品質向上、これらのサービスに関する利用者へのご案内の目的で利用することができるものとします。",
        },
        {
            title: "第11条   （利用停止及び解約）",
            list: [
                "利用者が以下の各号のいずれかの事由に該当すると当社が判断した場合、当社は事前に通知することなく、当該利用者による本サービスへのアクセスを禁止し、又は当該利用者が本サービスに関して当社に提供した情報の全部若しくは一部を削除することができるものとします（以下、総称して「利用停止」といいます。）。\n" +
                "(1)  本利用規約のいずれかの条項に違反した場合\n" +
                "(2)  第3条第4項各号の登録拒否事由のいずれかに該当した場合\n" +
                "(3)  前各号に準ずる事態が生じた場合\n" +
                "(4)  その他本サービスの利用又は登録が不適当である場合",
                "利用者が以下の各号のいずれかの事由に該当する場合、当社は利用者に通知の上、ただちに本利用契約を解約することができます。\n" +
                "(1)  前項各号のいずれかに該当した場合\n" +
                "(2)  当社が利用者の本サービスの利用、登録又は本利用契約の継続を適当でないと判断した場合\n",
                "前二項に定める措置は、当社から当該利用者に対する損害賠償請求を行うことを妨げるものではありません。また、前二項に定める措置を行ったことについて、当社は一切の損害賠償義務を負いません。",
            ]
        },
        {
            title: "第12条   （利用者による解約）",
            list: [
                "利用者は、いつでも当社に通知することにより、本利用契約を解除することができ、これにより、当社と利用者との契約関係は終了するものとします。",
                "利用者は、本利用契約が終了した場合であっても、当社及び第三者に対して既に生じた本利用契約上の一切の義務及び債務（損害賠償義務を含みますが、これに限りません。）を免れるものではありません。",
            ]
        },
        {
            title:'第13条   （本サービスの停止）',
            list: [
                "当社は以下のいずれかに該当する場合には、利用者に事前に通知することにより、本サービスの全部又は一部の提供を停止できるものとします。\n" +
                "(1)  本サービスに関わるハードウェア、ソフトウェア、通信機器その他関連する機器若しくはシステムの点検又は保守作業を定期的に又は緊急に行う場合\n" +
                "(2)  コンピューター若しくは通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング、その他予期せぬ要因により本サービスの提供が困難となった場合\n" +
                "(3)  本サービスに関するセキュリティ上の問題が生じた場合\n" +
                "(4)  天災地変、戦争、戦争のおそれ、封鎖、通商停止、革命、暴動、伝染病若しくはその他の疫病、物資若しくは施設の破壊若しくは損傷、火災、台風、地震、洪水、その他当社の支配を超える事由\n" +
                "(5)  法令等又はこれらに基づく措置により本サービスの運営が困難となった場合\n" +
                "(6)  その他前各号に準じる事由により当社が必要と判断した場合",
                "前項により当社が行った措置によって生じた損害について、一切の責任を負いません。",
            ]
        },
        {
            title: "第14条   （本サービスの変更・追加）",
            list: [
                "当社は、当社の都合により、本サービスの性質に重大な変更が生じない範囲で本サービスの内容の変更又は追加ができるものとします。",
                " 前項により当社が行った措置によって生じた損害について、一切の責任を負いません。"
            ]
        },
        {
            title:"第15条   （本サービスの終了）",
            list:[
                "当社は、利用者へ事前に通知することにより、本サービスの全部又は一部の提供を終了できるものとします。ただし、緊急の場合その他事前の通知が困難な場合には、事後に通知するものとします。",
                "本サービスの全部を終了する場合、当社は利用者に対して終了予定日の1週間前までに通知を行うものとします。",
                "第1項及び第2項により当社が行った措置によって生じた損害について、一切の責任を負いません。"
            ]
        },
        {
            title:"第16条   （損害賠償）",
            list:[
                "利用者による本利用規約に違反する行為又は本サービスに関連して利用者の責めに帰すべき事由により当社又は第三者に損害が生じたときは、利用者はその一切の損害につき賠償する義務を負うものとします。",
                "当社は、本サービス又は本利用規約に関連して利用者に生じた損害につき、当社の故意又は重過失に基づく場合を除き、一切の責任を負いません。",
                "当社の故意又は重過失により利用者に損害（現実に生じた直接かつ通常の損害に限る。）が生じた場合の当社の責任賠償額の総額は、直近1か月に利用者が本サービスを通じて取得したアマゾンポイントの合計金額を超えないものとします。"
            ]
        },
        {
            title:"第17条   （反社会的勢力の排除）",
            list:[
                "当社及び利用者は、自ら又はその役員（取締役、執行役、執行役員、監査役又はこれらに準ずる者をいう。）において、反社会的勢力等に該当しないこと、及び次の各号のいずれにも該当せず、かつ将来にわたっても該当しないことを確約し、これを保証するものとします。\n" +
                "(1)  反社会的勢力等が経営を支配していると認められる関係を有すること\n" +
                "(2)  反社会的勢力等が経営に実質的に関与していると認められる関係を有すること\n" +
                "(3)  自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること\n" +
                "(4)  反社会的勢力等に対して暴力団員等であることを知りながら資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること\n" +
                "(5)  役員又は経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること",
                " 当社及び利用者は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約し、これを保証します。\n" +
                "(1)  暴力的な要求行為\n" +
                "(2)  法的な責任を超えた不当な要求行為\n" +
                "(3)  取引に関して、脅迫的な言動をし、又は暴力を用いる行為\n" +
                "(4)  風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為\n" +
                "(5)  その他前各号に準ずる行為\n",
                " 当社及び利用者は、相手方が本条に違反した場合には、催告その他の手続きを要しないで、ただちに本利用契約を解除することができるものとします。",
                "当社及び利用者は、本条に基づく解除により相手方に損害が生じた場合であっても、当該損害の賠償義務を負いません。また、当該解除に起因して自己に生じた損害につき、相手方に対し損害賠償請求することができるものとします。",

            ]
        },
        {
            title:"第18条   （本利用規約の変更）",
            list: [
                "当社は、以下の各号のいずれかに該当する場合、本利用規約を変更することができるものとします。\n" +
                "(1)  本利用規約の変更が、利用者の一般の利益に適合するとき\n" +
                "(2)  本利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更にかかる事情に照らして合理的なものであるとき",
                "前項の場合、当社は、変更後の本利用規約の効力発生日の14日前までに、本利用規約を変更する旨及び変更後の本利用規約の内容とその効力発生日を、当社が適切と判断する方法（当社サイト・本サービス内への掲示、利用者に電子メールを送信する方法、本サービス上での通知等）により通知します。",
                "本利用規約の変更に同意しない利用者は、前項に定める変更の効力発生日の前日までに、本利用契約を解約するものとします。効力発生日までに本利用契約の解約を行わず、本サービスの利用を継続した場合には、当該変更に同意したものとみなします。",
                " 第1項に定める他、当社は、利用者の同意を得ることにより本利用規約を変更することができるものとします。",
                "当社は、本条に基づいた本利用規約の変更により利用者に損害が生じたとしても、一切の責任を負いません。"
            ]
        },
        {
            title:"第19条   （権利義務の譲渡禁止）",
            description: "利用者は、当社の事前の書面による承諾なく、本利用契約上の地位又は本利用契約に基づく権利若しくは義務を、第三者に譲渡、移転（合併若しくは会社分割による場合を含みます。）、担保設定又はその他の処分をしてはなりません。"
        },
        {
            title: "第20条   （分離可能性",
            description: "本利用規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本利用規約の残りの規定又は部分は、継続して完全に効力を有するものとし、当社と利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。",
        },
        {
            title: "第21条   （協議）",
            description: "本利用規約の解釈に疑義が生じた場合、又は本利用規約に定めのない事項については、当社と利用者は、誠意をもって協議の上これを解決するものとします。"
        },
        {
            title: "第22条   （準拠法及び管轄）",
            list: [
                "本利用契約は、日本法を準拠法として、日本法に従い解釈されるものとします。",
                " 本利用契約に関する紛争については、訴額に応じ東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。なお、調停を行う場合についても同様とします。"
            ]
        }
    ]
    const renderList = ({item, index}) => {
        return (
            <>
                <List.Section>
                    <Text variant={"bodySmall"}><Text variant={"bodySmall"}
                                                      style={{fontWeight: "bold"}}>{index + 1}. </Text> {item}</Text>
                </List.Section>
            </>
        )
    }
    const renderItems = ({item}) => {
        return (
            <>
                <FBox style={{paddingVertical: 16}}>
                    {item.title ? <Text variant={"bodySmall"} style={{fontWeight: "bold"}}>{item.title}</Text> : null}
                    {item.description ?
                        <Text style={{paddingVertical: 10}} variant={"bodySmall"}>{item.description}</Text> : null}
                    {item.list ? (
                        <>
                            <FBox style={{paddingVertical: 16}}>
                                <FlatList data={item.list} renderItem={renderList} horizontal={false}/>
                            </FBox>
                        </>
                    ) : null}
                </FBox>
            </>
        )
    }
    return (
        <>
            <FBox style={{flex: 1, padding: 16}}>
                <Text variant={"bodyLarge"} style={{paddingVertical: 16, fontWeight: "bold"}}>
                    DrugN α版利用規約（ユーザー用）
                </Text>
                <Divider/>
                <FlatList data={data} renderItem={renderItems}/>
            </FBox>
        </>
    );
}