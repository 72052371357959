import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {ScreenWidth} from "../../utils/constants";
import {captureImage, captureImageContext} from "../../utils/WebCameraUtils";
import {BarcodeReader, TextResult} from "dynamsoft-javascript-barcode";
import {SCANNER_KEY} from "@env";
import {Text} from "react-native-paper";

BarcodeReader.license = SCANNER_KEY || 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNzM2MzA2LVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNzM2MzA2IiwiY2hlY2tDb2RlIjo1MzUzNjc5MzF9';
BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.10/dist/";

interface ScannerCapturedPicture {
    width: number;
    height: number;
    uri: string;
    base64?: string;
}

interface ScannerRef {
    takePictureAsync: () => Promise<ScannerCapturedPicture>
}


const CAMERA_CONSTRAINTS: MediaStreamConstraints = {
    audio: false,
    video: {
        facingMode: 'environment',
    },
}

interface ScannerOptions {
    constraints?: MediaStreamConstraints;
    timeBetweenDecodingAttempts?: number;
    onResult?: (result: TextResult) => void;
    onError?: (error: Error) => void;
    zbar?: boolean;
}

const useScanner = ({
                        constraints = CAMERA_CONSTRAINTS,
                        timeBetweenDecodingAttempts = 300,
                        zbar = true,
                        onResult = () => {
                        },
                        onError = (err) => {
                            console.log("Scanning...")
                        },
                    }: ScannerOptions = {}) => {
    const ref = useRef<HTMLVideoElement>(null);
    const [scanning, setScanning] = useState(false)
    const stream = useRef<MediaStream | null>(null);
    const timeout = useRef<any>(null)
    const scanner = useRef<BarcodeReader | null>(null)
    const loadScanner = async () => {
        await BarcodeReader.loadWasm()
        scanner.current = await BarcodeReader.createInstance()
    }
    const startScanning = async () => {
        console.log("SCANNING....")
        if (scanner.current && ref.current && !scanning) {
            const imageData = captureImageContext(ref.current, {scale: 1});
            if (imageData && imageData.width > 0 && imageData.height > 0) {
                setScanning(true)
                const result = await scanner.current?.decode(imageData)
                result.forEach(res => {
                    onResult(res)
                })
                setScanning(false)
            }
        }
        timeout.current = setTimeout(() => startScanning(), timeBetweenDecodingAttempts)
    }
    useEffect(() => {
        if (ref.current && navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia(constraints).then(st => {
                stream.current = st
                if (ref.current) {
                    ref.current.srcObject = st
                    ref.current.play().catch(err => console.error("CAMERA PLAYING ERROR\n" + err))
                }
            }).catch(err => console.log("CAMERA ERROR", err))
        }
        return () => {
            if (stream.current) {
                stream?.current.getVideoTracks()[0].stop();
                if (ref.current) {
                    ref.current.srcObject = null
                }
            }
        }
    }, [ref, constraints])
    useEffect(() => {
        loadScanner().then(() => startScanning()).catch(err => console.error("SCANNING ERROR ****\n" + err))
        return () => {
            clearTimeout(timeout.current)
            scanner.current?.destroyContext()
        }
    }, [])
    return {ref, scanner, scanning};
};

interface ScannerInterface {
    onResult?: (result: TextResult) => void;
    style?: { [x: string]: string };
}

export const Scanner = React.forwardRef((props: ScannerInterface, ref: React.ForwardedRef<ScannerRef>) => {

    const hints = new Map();

    const {ref: cameraRef, scanning} = useScanner(
        {
            constraints: CAMERA_CONSTRAINTS,
            onResult: props.onResult
        })
    useImperativeHandle(ref, () => ({
        async takePictureAsync() {
            let base64 = "";
            if (cameraRef.current) {
                base64 = captureImage(cameraRef.current, {})
            }
            return {width: 0, height: 0, uri: base64, base64}
        }
    }), [])

    return <>
        {scanning && <Text style={{position: "absolute", top: 10, left: 10}}>Scanning</Text>}
        <video ref={cameraRef} playsInline muted autoPlay
               style={{
                   height: ScreenWidth * .7,
                   width: ScreenWidth,
                   objectFit: "cover",
                   overflow: "clip",
                   ...props.style
               }}/>
    </>


})